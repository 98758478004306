import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { StorageMap } from '@ngx-pwa/local-storage';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Users } from './auth.model';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token:any

  currentPath:any;

  public user = new BehaviorSubject<any>(null)
  users$ = this.user.asObservable()

  users!:Users;

  constructor(
    private http: HttpClient,
    private storage: StorageMap
  ) {

  }

  validationToken(){
    const helper = new JwtHelperService();
  }



  login(data:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return lastValueFrom(this.http.post<any>(`${environment.apiUrl}/cms/auth/login`, data, { headers }));
  }

  loginAdfs() {
    const headers = new HttpHeaders({
      'AppID': '901c456c0835e8ac624e955c81fa01ae'
    });
    const isLocal = window.location.hostname === 'localhost';

    let param = new HttpParams({
      fromObject: {
        'relayState': `https://cms-mie2024.unictive.net/authentication/login${isLocal ? '/dev' : ''}`,
        'tokenDuration': '3600',
      }
    });
    return lastValueFrom(this.http.get<any>(`${environment.apiUrladfs}/adfs/auth`+(param ? '?'+param : ''), { headers: headers }));
  }

  GET_UserData(){
    // lastValueFrom(this.http.get<any>(`${environment.apiUrl}/cms/v1/am/admin/info`)).then(res=>{
    lastValueFrom(this.http.get<any>(`${environment.apiUrl}/cms/auth/info`)).then(res=>{
      if(res.success){
        this.user.next(res.data);
        this.users = res.data;
        lastValueFrom(this.storage.set('users', res.data));
      }
    })
  }

  GET_UserDataAdfs(token: string){
    const headers = new HttpHeaders({
      'AppID': '901c456c0835e8ac624e955c81fa01ae',
      'Authorization': `Bearer ${token}` // Assuming you have a method to get the token
    });
    return lastValueFrom(this.http.get<any>(`${environment.apiUrladfs}/adfs/user-details`, { headers: headers }));
  }

  userdata(){
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/cms/v1/am/admin/info`)).then(res=>{
      if(res.success){
        this.user.next(res.data);
        this.users = res.data;
        return res.data;
      }
    })
  }

  // validateToken(): Observable<boolean> {
  //   const validateTokenUrl = `${environment.apiUrl}/auth/validate-token`; // Replace with your actual API endpoint
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${this.token}` // Assuming you have a method to get the token
  //   });

  //   return this.http.get<boolean>(validateTokenUrl, { headers });
  // }


  logout(){
    lastValueFrom( this.storage.delete('token-x'));
    lastValueFrom( this.storage.delete('token-adfs'));
    lastValueFrom( this.storage.delete('users'));
    lastValueFrom( this.storage.delete('eventData'));
    // lastValueFrom( this.storage.delete('users'));
    // lastValueFrom( this.storage.delete('property'));
    // this.sesServ.removeRole();
      // this.navbar(true);
      // setTimeout(()=>{
      //     window.location.reload();
      //   },1000);

  }

  logout_end_session(){
    lastValueFrom( this.storage.delete('token-x'));
    // lastValueFrom( this.storage.delete('token-adfs'));
    lastValueFrom( this.storage.delete('users'));
    lastValueFrom( this.storage.delete('eventData'));
    // lastValueFrom( this.storage.delete('users'));
    // lastValueFrom( this.storage.delete('property'));
    // this.sesServ.removeRole();
      // this.navbar(true);
      // setTimeout(()=>{
      //     window.location.reload();
      //   },1000);
  }

  logoutAdfs(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.storage.get('token-adfs').subscribe((tokenAdfs: any) => {
        const token = tokenAdfs as string;
        if (!token) {
          reject('Token not found');
          this.logout();
          window.location.href = '/authentication/login';
          return;
        }
        const headers = new HttpHeaders({
          'AppID': '901c456c0835e8ac624e955c81fa01ae',
          'Authorization': `Bearer ${token}`
        });
        const isLocal = window.location.hostname === 'localhost';
        let param = new HttpParams({
          fromObject: {
            'relayState': `https://cms-mie2024.unictive.net/authentication/login${isLocal ? '/dev' : ''}`,
            'tokenDuration': '3600',
          }
        });
        lastValueFrom(this.http.get<any>(`${environment.apiUrladfs}/adfs/logout` + (param ? '?' + param : ''), { headers: headers }))
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    });
  }

  async showAlertCreds(title: any, message: any, showBg: boolean = false): Promise<boolean> {
    return Swal.fire({
      html: `
      <img class="" src="assets/icon/icon-warning.svg" alt="Icon Warning" />
      <h4 class="text-[18px] lg:text-[24px] mb-5">${title}</h4>
      <p class="text-2xl mb-6 font-semibold">${message}</p>
      `,
      showCancelButton: false,
      confirmButtonText: 'OK',
      confirmButtonColor: '#3D489C',
      customClass: {
      confirmButton: 'custom-confirm-button !rounded-full font-bold',
      },
      backdrop: showBg ? `
      rgba(0,0,123,0.4)
      url("assets/media/bg-login-new.jpg")
      center
      no-repeat
      ` : '',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      preConfirm: () => {
        this.logoutAdfs().then((res) => {
          if (res.output_schema) {
            setTimeout(() => {
              const externalUrl = res.output_schema.logout_url;
              if (externalUrl) {
                this.logout();
                window.location.href = externalUrl;
              } else {
                this.logout();
                window.location.reload();
              }
            }, 500);
          }
        });
        return false; // Prevent closing the Swal
      }
    }).then((result) => result.isConfirmed);
  }
}
