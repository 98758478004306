import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


import { tap , map, Observable} from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from 'src/app/api/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SessionsService } from '../sessions/sessions.service';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  validation = new JwtHelperService();

  constructor(
    private auth: AuthService,
    protected storage : StorageMap,
    private router: Router,
    private sessionsServ : NgxPermissionsService,
    private sessions: SessionsService
    // private socialAuth: SocialAuthService
    ) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate() {
    return this.storage.get('token-x').pipe(
      map((token:any)=>{
        if(token){

          let isExipred = this.validation.isTokenExpired(token);

          if(isExipred){
            this.auth.logout_end_session();
            this.router.navigate(['authentication/login']).then(() => {
              this.auth.logoutAdfs().then((res) => {
                if (res.output_schema) {
                  // this.helper.showSuccessAlert('Success', 'Logout berhasil.');
                  setTimeout(() => {
                    const externalUrl = res.output_schema.logout_url;
                    if (externalUrl) {
                      this.auth.logout();
                      window.location.href = externalUrl;
                    }
                  }, 500);
                }
              });
              // window.location.reload();
            });
            return false;

          } else {
            this.auth.token = token;
            this.sessions.loadSession();
            this.auth.users$.subscribe((res: any) => {
              if (res) {
                if (res.roleName.toUpperCase() === 'USER') {
                  this.auth.showAlertCreds('', 'Sorry, we can\'t find your account.<br>Please ensure that you\'ve<br>already registered as an admin.', true);
                }
              }
            });
            return true;
          }

        } else {
          // this.authService.removeSession();
          this.auth.token = false;
          this.router.navigate(['authentication/login']);
          return false
        }
      })
    )
  }

}


@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(
    protected storage : StorageMap,
    private router: Router,
    ) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate() {
    return this.storage.get('token-x').pipe(
      map(data=>{
        if(data){
          this.router.navigate(['u/dashboard']);
          return false;
        } else {
          return true;
        }
      })
    )
  }

}
