@if (data.totalPage > 0) {
  <div class="relative h-fit">
    <div class="flex w-full justify-center">
      <p-paginator
        (onPageChange)="onPageChange($event)"
        [first]="first"
        [rows]="data.limit"
        [pageLinkSize]="onPages($event)"
        [totalRecords]="data.totalPage"
        [showFirstLastIcon]="true">
        <ng-template pTemplate="firstpagelinkicon">
          <button type="button" class="p-paginator-first p-paginator-element p-link">First</button>
        </ng-template>
        <ng-template pTemplate="lastpagelinkicon">
          <button type="button" class="p-paginator-last p-paginator-element p-link">Last</button>
        </ng-template>
      </p-paginator>
    </div>

    @if (showPaginator) {
      <!-- <div class="lg:absolute lg:top-0 lg:translate-x-0 lg:left-auto w-full lg:w-auto lg:end-0 content-center" style="height: -webkit-fill-available;"> -->
      <div class="lg:absolute lg:top-0 lg:translate-x-0 lg:left-auto w-full lg:w-auto lg:end-0 lg:h-[68px] content-center">
        <div class="flex justify-center lg:justify-end items-center">
          <span class="mx-1 text-color">Rows per page: </span>
          <p-dropdown [options]="options" (onChange)="onLimitChange($event)" placeholder="Select" [disabled]="data.totalPage < 15" />
        </div>
      </div>
    }
  </div>
}
